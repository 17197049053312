.admin {
  max-width: 720px;
  margin: 40px auto 0 auto;
  line-height: 1.5em;
  color: rgb(255, 144, 184);
}

.admin .section {
  margin: 0 auto 20px auto;
  padding: 15px;
  border: 4px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to bottom,
    rgba(136, 255, 0, 0.7) 0%,
    rgba(255, 0, 221, 0.7) 100%
  );
}

.admin .collapsibleHeader {
  font-size: 15pt;
  color: white;
  background-color: transparent;
  border-image: linear-gradient(
      to right,
      rgb(124, 160, 118) 30%,
      transparent 70%
    )
    1;
  padding: 3px 20px;
  margin: 7px;
  cursor: pointer;
}

.admin .collapsibleHeader:hover {
  background: rgb(128, 47, 78);
  color: #fff;
}

.admin label {
  font-size: 0.8em;
  font-weight: bold;
  display: block;
  margin: 5px auto 0 auto;
  padding: 0;
}

.admin .checkbox {
  width: 25px;
  height: 25px;
  margin: 10px 10px;
}

.admin .checkboxLabel {
  display: inline;
  cursor: pointer;
  user-select: none;
}

.admin .hint {
  color: rgb(104, 101, 207);
  line-height: 1.2em;
}

.admin .preview {
  font-size: 12pt;
  color: rgb(96, 247, 212);
}

.admin .table {
  font-size: 12pt;
  color: white;
}

.admin span,
small {
  display: block;
  margin-bottom: 6px;
  font-size: 0.55em;
}

.admin input,
textarea,
select {
  max-width: 480px;
  padding: 5px;
  margin: 3px 0;
  color: #777;
  width: 100%;
  border-radius: 7px;
  font-size: 12pt;
}

.admin input::placeholder {
  color: rgb(197, 197, 197);
  font-style: italic;
}

.admin .error {
  color: rgb(255, 70, 70);
  font-size: 1.2em;
  margin-top: 20px;
}

.admin .forbidden {
  color: rgb(17, 255, 176);
  font-size: 2em;
}

.admin .success {
  color: rgb(151, 255, 123);
}

.admin .comboList ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.admin .comboList li {
  font-size: 0.7em;
  line-height: 1.4em;
  padding: 2px 20px;
  margin: 0 2px;
  border: 1px solid #676767;
  color: rgb(147, 149, 147);
  list-style-type: none;
  cursor: pointer;
}

.admin .comboList li:hover {
  background-color: #7bb68a;
  color: white;
}

/* selected element from the query results displayed */
.admin .comboList li.selected {
  background-color: #d2ea65;
  color: black;
  font-weight: bold;
}

.admin .list-container {
  height: 100px;
  overflow: auto;
  max-width: 480px;
  padding: 0 0;
  margin: 0 0;
}

.admin .list-container > *:first-child {
  margin-top: 0;
}

.admin .list-container > *:last-child {
  margin-bottom: 0;
}

.admin .list-container::-webkit-scrollbar {
  width: 10px;
}

.admin .list-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.admin .list-container::-webkit-scrollbar-thumb {
  background-color: #bc5050;
  border-radius: 5px;
}

.admin .list-container::-webkit-scrollbar-thumb:hover {
  background-color: #bc5050;
}

.admin .circle {
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 3px solid var(--admin-color);
  text-align: center;
  line-height: 45px;
  font-size: 1em;
}
