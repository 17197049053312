.blogs {
  max-width: 767px;
  margin: 20px auto;
  padding: 20px;
}

.blogs h1 {
  font-size: 3em;
}

.blogs h2 {
  font-size: 1.8em;
}

.blogs .story-title {
  text-align: center;
  color: rgb(104, 198, 137);
}

.blogs .copy-link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1em;
}
