.content-block {
  padding: 4px 0;
}

.content-block pre {
  white-space: pre-wrap;
}

.content-block img {
  max-width: 100%;
  height: auto;
}

.content-block a {
  color: rgb(41, 222, 107);
  text-decoration: none;
  /* text-decoration-style: dotted; */
}

.content-block a:visited {
  color: rgb(30, 153, 92);
}

.download {
  padding: 10px 0;
}
